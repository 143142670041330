import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
const langs = import.meta.glob("./lang/*.json", {
    eager: true
});
export function process_langs() {
    let _langs = {};
    for (const lang in langs) {
        const code = lang.split("/").pop().split(".").shift();
        _langs[code] = langs[lang].default;
    }
    return _langs;
}
const processed_langs = process_langs();
for (const lang in processed_langs) {
    addMessages(lang, processed_langs[lang]);
}
export async function setupi18n() {
    await init({
        fallbackLocale: "en",
        initialLocale: getLocaleFromNavigator()
    });
}
